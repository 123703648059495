import * as React from "react";
import { Link } from "gatsby";

import { Button, Box, Heading, Text, useColorMode } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const IndexPage = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      {/* <Text color="primary">here's the new color!</Text>
        <Box bg="gray.600">here's the new color!</Box>
        <Text color="test">here's the new color!</Text> */}

      <Box
        // border="1px"
        // borderColor="gray.200"
        m="auto"
        // bg={colorMode === "light" ? "white" : "gray.900"}
      >
        <Box mt="130px">
          <Text
            fontSize={{ base: "44px", md: "68px" }}
            fontWeight="extrabold"
            lineHeight="1.1"
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            bgClip="text"
            maxW="2xl"
          >
            Réalignez vision, produit et équipes
          </Text>
          <Text
            fontSize="2xl"
            my="40px"
            maxW="2xl"
            color={colorMode === "light" ? "gray.600" : "gray.300"}
          >
            PARSK vous aide à transformer votre Stratégie en Produit, parce que
            la réussite d'une entreprise dépend de l'alignement entre la
            stratégie et sa traduction en expérience auprès des collaborateurs
            et des clients.
          </Text>
        </Box>
      </Box>

      <Link to="/why">
        <Heading
          mt="80px"
          size="xl"
          fontWeight="extrabold" // maxW="48rem" maxW="2xl"
          lineHeight="1.2"
          letterSpacing="tight"
          textTransform="uppercase"
          _hover={{ color: "#199ECF" }}
        >
          Pourquoi <ChevronRightIcon />
        </Heading>
      </Link>
      <Link to="/what">
        <Heading
          as="h2"
          mt="40px"
          size="xl"
          fontWeight="extrabold"
          // maxW="48rem"
          maxW="2xl"
          lineHeight="1.2"
          letterSpacing="tight"
          textTransform="uppercase"
          _hover={{ color: "#44CF6E" }}
        >
          Quoi <ChevronRightIcon />
        </Heading>
      </Link>
      <Link to="/how">
        <Heading
          as="h2"
          mt="40px"
          size="xl"
          fontWeight="extrabold"
          // maxW="48rem"
          maxW="2xl"
          lineHeight="1.2"
          letterSpacing="tight"
          textTransform="uppercase"
          _hover={{ color: "#CF9B23" }}
        >
          Comment <ChevronRightIcon />
        </Heading>
      </Link>

      <Link to="/who">
        <Heading
          as="h2"
          mt="40px"
          size="xl"
          fontWeight="extrabold"
          // maxW="48rem"
          maxW="2xl"
          lineHeight="1.2"
          letterSpacing="tight"
          textTransform="uppercase"
          _hover={{ color: "#121FCF" }}
        >
          Pour qui <ChevronRightIcon />
        </Heading>
      </Link>

      <Footer />

      {/* <Why />
      <What /> */}
    </Box>
  );
};

export default IndexPage;
